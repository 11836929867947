<template>
    <div class="auth-page">
        <RegisterForm isModal @showModal="showModal"/>
        <Modal v-model="modalData.show">{{ modalData.text }}</Modal> 
    </div>
</template>

<script>
import RegisterForm from '@/components/auth/RegisterForm'
import Modal from '@/components/elements/Modal'
import { reactive } from 'vue'

export default ({
    components: {
        RegisterForm,
        Modal
    },
    setup() {
        const modalData = reactive({
            show: false,
            text: ''
        })

        function showModal(text) {
            modalData.show = true
            modalData.text = text
        }

        return {
            showModal,
            modalData
        }
    },
})
</script>


<style lang="scss" scoped>
.auth-page {
    height: 100%;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style> 